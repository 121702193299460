/**
 * Default Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import Header from '@components/Header'
import './default.css'

const theme = {
  darkPrimary: '#351733',
  lightPrimary: '#D1C4E9',
  primary: '#673AB7',
  text: '#FFFFFF',
  accent: '#FF5252',
  primaryText: '#212121',
  secondaryText: '#757575',
  dividerColor: '#BDBDBD',
  spacing: 8
}

// const theme = {
//   darkPrimary: '#000022',
//   lightPrimary: '#D1C4E9',
//   primary: '#002266',
//   text: '#FFFFFF',
//   accent: '#512DA8',
//   primaryText: '#212121',
//   secondaryText: '#757575',
//   dividerColor: '#BDBDBD',
//   spacing: 8
// }

const DefaultLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            style={
              {
                // margin: `0 auto`,
                // maxWidth: 960,
                // padding: `0px 1.0875rem 1.45rem`,
                // paddingTop: 5,
              }
            }
          >
            <main>{children}</main>
            {/* <footer>
              © {new Date().getFullYear()}, Built with
              {` `}
              <a href="https://www.gatsbyjs.org">Gatsby</a>
            </footer> */}
          </div>
        </>
      </ThemeProvider>
    )}
  />
)

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default DefaultLayout
