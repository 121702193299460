import React from 'react'

import Layout from '../layouts/default'
import SEO from '../components/seo'
import Yoda from '../components/Yoda'
import styled from 'styled-components'

const Cont = styled.div`
  text-align: center;
`

const YodaCont = styled.div`
  max-width: 400px;
  margin: 0 auto;
`

const Title = styled.h1`
  margin: ${ props => props.theme.spacing * 4 }px 0;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Cont>
      <Title>Ouch, Looks like you got lost.</Title>
      {/* <p>Page you're looking for, this is not.</p> */}
      <YodaCont>
        <Yoda />
      </YodaCont>
    </Cont>
  </Layout>
)

export default NotFoundPage
